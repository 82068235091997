import * as xl_common from "../xl_common";
//import $ from "jquery";

export async function get_from_ranges(range_array, context) {
  return xl_common.get_row_objects(range_array, get_map(), context);
}
export function get_map() {
  return {
    cap: 0,
    available: 1,
    name: 2,
    title: 3,
    primary_opportunity: 4,
    primary_start: 5,
    primary_end: 6,
    primary_percent: 7,
    primary_owner: 8,
    primary_market: 9,
    secondary_opportunity: 10,
    secondary_owner: 11,
    current_engagement: 12,
    current_market: 13,
    current_owner: 14,
    manager: 15,
    build_center: 16,
    physical_location: 17,
    capability: 18,
    homeroom: 19,
    skills: 20,
    skills_detail: 21,
    notes: 22,
    secondary_start_date: 23,
    ml_engagement: 24,
    region: 25,
    investment: 26,
  };
}
export async function find(search_text, context, search_in) {
  if (!search_in) {
    search_in = context.workbook.worksheets.getItem("Staffing");
  }
  return xl_common.find_row_objects(search_text, search_in, get_from_ranges);
}
export async function parse_key(key) {
  key = key.replace("Ext. at ", "");
  var hyphen_location = key.indexOf(" - ");
  if (hyphen_location > 0) {
    let client = key.substring(0, hyphen_location);
    let project = key.substring(hyphen_location + 3);
    return {
      client: client,
      project: project,
    };
  } else {
    return {
      client: null,
      project: key,
    };
  }
}
