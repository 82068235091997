import * as React from "react";

function skillsObject(skillsString) {
  const skillsArray = skillsString.split(";#").map((skill) => {
    if (skill.includes("-")) return skill.split("-");
    else return ["other", skill];
  });
  const sortedSkills = skillsArray.sort();
  let currentCategory = "";
  let result = {};
  sortedSkills.forEach((element) => {
    if (element[0] !== currentCategory) {
      currentCategory = element[0];
      result[currentCategory] = [];
    }
    result[currentCategory].push(element[1]);
  });
  return result;
}

export default function Skills(props) {
  const displayTitle = !props.hideTitle;
  if (props.skills) {
    const skills = skillsObject(props.skills ? props.skills : "");
    return (
      <div>
        {displayTitle && <h4>Skills</h4>}
        <table class="skills_table">
          {Object.keys(skills).map((key) => (
            <tr>
              <td class="skill_category">{key}</td>
              <td>{skills[key].join(", ")}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  } else {
    return <div/>;
  }
}
