import React from "react";
import * as xl_common from "../../staffing_modules/xl_common";

export default function OpportunitySummary(props) {
  return (
    <table>
      <tr>
        <td className="label">Priority:</td>
        <td id="priority">{props.opportunity.priority}</td>
        <td className="label">Prob:</td>
        <td id="probability">{props.opportunity.probability}</td>
      </tr>
      <tr>
        <td className="label">Start:</td>
        <td id="start_date">{xl_common.excel_date_to_string(props.opportunity.start_date)}</td>
        <td className="label">End:</td>
        <td id="end_date">{xl_common.excel_date_to_string(props.opportunity.end_date)}</td>
      </tr>
      <tr>
        <td className="label">Market:</td>
        <td id="market">{props.opportunity.market}</td>
        <td className="label">Owner:</td>
        <td id="owner">{props.opportunity.owner}</td>
      </tr>
      <tr>
        <td className="label">Staffable:</td>
        <td id="staffable">{props.opportunity.staffable}</td>
        <td className="label">Cap:</td>
        <td id="cap">{props.opportunity.lead_capability}</td>
      </tr>
      <tr>
        <td className="label">Market:</td>
        <td id="cap">{props.opportunity.market}</td>
        <td className="label">S@R</td>
        <td id="staffable">{props.opportunity.start_at_risk}</td>
      </tr>
      <tr>
        <td className="label">Cross Border:</td>
        <td id="cap">{props.opportunity.can_cross_border}</td>
      </tr>
      {(props.opportunity.meta && 
      <tr>
        <td className="label">Accurate:</td>
        <td>{props.opportunity.meta && (props.opportunity.meta.initials)}</td>
        <td className="label">Cloud:</td>
        <td>{props.opportunity.meta && (props.opportunity.meta.cloud)}</td>
      </tr>)}
      <tr>
        <td className="label">SA:</td>
        <td id="staffable">{props.opportunity.num_cds_sa ? props.opportunity.num_cds_sa : "0"}</td>
        <td className="label">ENG:</td>
        <td id="cap">{props.opportunity.num_cds_eng}</td>
      </tr>
    </table>
  );
}
