import React from "react";
import AlignmentTable from "./alignmentTable";
import OpportunitySummary from "./OpportunitySummary";
//import Button from "@mui/material/Button";

function encodeBreaks(str) {
  return str.replace(/(\r\n|\n|\r)/g, "<br />");
}
function tagArrayToString(tagArray) {
  return tagArray.join(", ");
}

export default function Opportunity(props) {
  const opp = props.opportunity;
  return (
    <div>
      <h2 className="project">
        {opp.meta && typeof opp.meta === 'object' && opp.meta.flag && opp.meta.flag == "SOLVED" && (<span style={{ color : opp.meta && opp.meta.flag && opp.meta.flag == "SOLVED" ? 'green' : '' }}>SOLVED: </span>)}
        {opp.client} - {opp.opportunity}
      </h2>
      <OpportunitySummary opportunity={opp} />

      {opp.alignments && opp.alignments.primary && (
        <div>
          <h3>Primary Alignments</h3>
          <AlignmentTable type="primary" alignments={opp.alignments.primary} />
        </div>
      )}
      {opp.alignments && opp.alignments.secondary && (
        <div>
          <h3>Secondary Alignments</h3>
          <AlignmentTable type="secondary" alignments={opp.alignments.secondary} />
        </div>
      )}
      {opp.alignments && opp.alignments.current && (
        <div>
          <h3>Current Alignments</h3>
          <AlignmentTable type="current" alignments={opp.alignments.current} />
        </div>
      )}
      {opp.tags && (
        <div>
          <h3>Tags</h3>
          <div id="tags">{tagArrayToString(opp.tags)}</div>
        </div>
      )}
      {opp.meta &&  typeof opp.meta === 'object' && opp.meta.comment && (
        <div><h3>Meta Comment</h3>
        <div dangerouslySetInnerHTML={{ __html: encodeBreaks(opp.meta.comment) }}></div>
        </div>
      )}
      {opp.notes && (
      <div>
        <h3>Notes</h3>
        <div dangerouslySetInnerHTML={{ __html: encodeBreaks(opp.notes) }}></div>
      </div>
    )}
  </div>
  );
}
