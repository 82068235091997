import $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";
import StaffingEntry from "../../taskpane/components/StaffingEntry";

export function display(staffing_entry) {
  try {
    ReactDOM.render(<StaffingEntry staffing_entry={staffing_entry}/>, document.getElementById("sidecar_content"));
  } catch (e) {
    $("#message").html("error " + e + " " + staffing_entry.skills);
  }
}
