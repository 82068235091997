import * as React from "react";
import Skills from "./Skills";
import * as XlCommon from "../../staffing_modules/xl_common";

export default function StaffingEntry(props) {
  const staffing_entry = props.staffing_entry;
  return (
    <div>
      <h2>{staffing_entry.name}</h2>
      <h4>{staffing_entry.title}, {staffing_entry.build_center}</h4>
      <table>
        <tr><td class="label">Manager</td><td>{staffing_entry.manager}</td></tr>
        <tr><td class="label">Available</td><td>{XlCommon.excel_date_to_string(staffing_entry.available)}</td></tr>
        <tr><td class="label">Primary</td><td>{staffing_entry.primary_opportunity}, {staffing_entry.primary_percent}</td></tr>
        {staffing_entry.secondary_opportunity && (
          <tr><td class="label">Secondary</td><td>{staffing_entry.secondary_opportunity}</td></tr>
        )}
        <tr><td class="label">Current</td><td>{staffing_entry.current_engagement}</td></tr>
        {staffing_entry.notes && (
          <tr><td class="label">Notes</td><td>{staffing_entry.notes}</td></tr>
        )}
        {staffing_entry.skills && (<tr><td class="label">Skills</td><td><Skills skills={staffing_entry.skills} hideTitle="true" /></td></tr>)}
      </table>
    </div>
  );
}
