import $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";
import OpportunityDisplay from "../../taskpane/components/OpportunityDisplay";

export function display(opportunity) {
  try {
    ReactDOM.render(<OpportunityDisplay opportunity={opportunity}/>, document.getElementById("sidecar_content"));
  } catch (e) {
    $("#message").html("error " + e);
  }
}
