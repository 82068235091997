/*=============================================================================
HELPER
=============================================================================*/
export async function get_row_objects(range_array, map, context) {
  //nested functions
  async function get_rows_values(range_array, context) {
    const range_rows = range_array.map((range) => {
      const range_row = range.getEntireRow().getUsedRange();
      range_row.load("columnIndex,values");
      return range_row;
    });
    await context.sync();
    return range_rows.map((range_row) => {
      const columnOffset = range_row.columnIndex;
      if (columnOffset) return Array(columnOffset).fill(undefined).concat(range_row.values[0]);
      else return range_row.values[0];
    });
  }
  const rows_values = await get_rows_values(range_array, context);
  const result = rows_values.map((row_values) => {
    var row_object = {};
    for_each_field_in_object(map, (key, columnIndex) => {
      if (row_values[columnIndex]) row_object[key] = row_values[columnIndex];
    });
    return row_object;
  });
  return result;
}
export function for_each_field_in_object(field_map, action) {
  for (const key in field_map) {
    if (Object.hasOwnProperty.call(field_map, key)) {
      action(key, field_map[key]);
    }
  }
}
export async function find_row_objects(search_text, search_in, object_builder) {
  const context = search_in.context;
  const found_areas = search_in.findAllOrNullObject(search_text, null);
  var results = [];
  found_areas.load("areas");
  await context.sync();
  try {
    const row_objects = await object_builder(found_areas.areas.items, context);
    for (var i = 0; i < row_objects.length; i++) {
      results.push({
        cell: found_areas.areas.items[i],
        row_object: row_objects[i],
      });
    }
  } catch (e) {
    //console.error(error);
  }
  return results;
}
export function excel_date_to_js_utc_date(serial) {
  return new Date(Math.floor(serial - 25569) * 86400000);
}
export function excel_date_to_string(serial) {
  var date_info = excel_date_to_js_utc_date(serial);
  const year = date_info.getUTCFullYear();
  const month = (date_info.getUTCMonth() + 1).toString().padStart(2, "0");
  const day_of_month = date_info.getUTCDate().toString().padStart(2, "0");
  const result = year + "-" + month + "-" + day_of_month;
  return result;
}
