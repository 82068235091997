import * as xl_common from "../xl_common";
import * as xl_staffing_entries from "../staffing_entries/xl_staffing_entries";
import * as opp_tags from "./tags";
import * as opp_meta from "./xl_metadata"
import $ from "jquery";


export async function get_from_ranges(range_array, context) {
  var opps = await xl_common.get_row_objects(range_array, get_map(), context);


  await Promise.all(
    opps.map(async (opp) => {
      try {
        opp.meta = await opp_meta.getMetaData(opp.client + ' - ' + opp.opportunity , context);
      } catch (meta_error) {
        console.log ("error getting meta data: " + meta_error);
      }
      opp.alignments = await find_alignments.for_opportunity(opp, context);
      opp.tags = opp_tags.getTagsPlusClouds(opp.notes, opp.opportunity, opp.se_skills);
    })
  );
  return opps;
}

export function get_map() {
  return {
    lead_capability: 0,
    priority: 1,
    client: 5,
    opportunity: 6,
    work_type: 7,
    start_date: 8,
    end_date: 9,
    probability: 10,
    owner: 11,
    market: 12,
    staffable: 13,
    start_at_risk: 14,
    can_cross_border: 15,
    vax_requirements: 16,
    required_timezones: 17,
    required_bc: 18,
    num_so: 19,
    num_xd: 20,
    num_qe: 21,
    num_se_sa: 22,
    num_se_eng: 23,
    num_cds_sa: 25,
    num_cds_eng: 26,
    num_de_sa: 28,
    num_de_eng: 29,
    se_skills: 31,
    notes: 32,
    region: 34,
    link_to_salesforce: 35,
    owner_location: 42
  };
}

export const find_alignments = {
  for_key: async function (key, context) {
    const found_staffing = await xl_staffing_entries.find(key, context, null);
    var alignments = {};
    found_staffing.forEach((found_item) => {
      var cell = found_item.cell;
      const column_map = xl_staffing_entries.get_map();
      //Determine which opportunity column each match was found in, and set the
      //opportunity type.
      switch (cell.columnIndex) {
        case column_map.primary_opportunity:
          if (!alignments.primary) alignments.primary = [];
          alignments.primary.push(found_item.row_object);
          break;
        case column_map.secondary_opportunity:
          if (!alignments.secondary) alignments.secondary = [];
          alignments.secondary.push(found_item.row_object);
          break;
        case column_map.current_engagement:
          if (!alignments.current) alignments.current = [];
          alignments.current.push(found_item.row_object);
          break;
      }
    });
    return alignments;
  },
  for_opportunity: async function (opportunity, context) {
    const key = get_staffing_key(opportunity);
    return find_alignments.for_key(key, context);
  },
  for_current_cell: async function (context) {
    const active_cell = context.workbook.getActiveCell();
    const opportunity = (await get_from_ranges([active_cell], context))[0];
    return find_alignments.for_opportunity(opportunity, context);
  },
};
export async function find(search_text, context, search_in) {
  if (!search_in) {
    search_in = context.workbook.worksheets.getItem("Opportunities");
  }
  return xl_common.find_row_objects(search_text, search_in, get_from_ranges);
}
export function get_staffing_key(opportunity) {
  return opportunity.client + " - " + opportunity.opportunity;
}
