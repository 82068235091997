function hasCloud(cloud, notes, projectName, seSkills) {
  const regexstring = "(^|\\W)" + cloud + "(?:$|\\W)";
  const regex = new RegExp(regexstring, "gi");
  return regex.test(notes + " " + projectName + " " + seSkills);
}
export function getTagsArray(notes) {
  try {
    const matchedTags = notes.match(/#\S+/gim);
    if (matchedTags) {
      const lowercasedTags = matchedTags.map((item) => {
        const lc = item.toLowerCase();
        if (lc === "#aws" || lc === "#gcp" || lc === "#azure") return lc;
        else return item;
      });
      return lowercasedTags;
    } else return matchedTags;
  } catch (error) {
    console.log("error in hasCloud() - " + error);
    return [];
  }
}

export function getTagsPlusClouds(notes, projectName, seSkills) {
  function addToArrayIfMissing(myArray, valueToAdd) {
    if (!myArray) myArray = [];
    if (myArray.includes(valueToAdd) == false) {
      myArray.push(valueToAdd);
    }
    return myArray;
  }
  var tags = getTagsArray(notes);
  if (hasCloud("aws", notes, projectName, seSkills) || hasCloud("amazon", notes, projectName)) {
    tags = addToArrayIfMissing(tags, "#aws");
  }
  if (hasCloud("gcp", notes, projectName, seSkills)) {
    tags = addToArrayIfMissing(tags, "#gcp");
  }
  if (hasCloud("Azure(?! DevOps)", notes, projectName, seSkills)) {
    tags = addToArrayIfMissing(tags, "#azure");
  }
  return tags;
}
