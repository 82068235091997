/*
 * Staffing Excel Task Pane
 * Matt Houser - matth@slalom.com
 */

// images references in the manifest
import "../../assets/b-icon-16.png";
import "../../assets/b-icon-32.png";
import "../../assets/b-icon-80.png";
import * as xl_events from "../staffing_modules/xl_events";

/* global console, document, Excel, Office */

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    document.getElementById("sideload-msg").style.display = "none";
    //document.getElementById("app-body").style.display = "flex";
    document.getElementById("app-body").style.display = "block";
    //document.getElementById("run").onclick = run;
    initialize().then({});
  }
});

/*=============================================================================
  Initialize
=============================================================================*/
async function initialize() {
  await Excel.run(function (context) {
    try {
      console.log("starting");
      const oppSheet = context.workbook.worksheets.getItem("Opportunities");
      const opp_selected_event_hook = xl_events.make_event_hook(xl_events.opportunity_active_cell);
      oppSheet.onSelectionChanged.add(opp_selected_event_hook);

      const staffSheet = context.workbook.worksheets.getItem("Staffing");
      const staff_selected_event_hook = xl_events.make_event_hook(xl_events.staffing_active_cell);
      staffSheet.onSelectionChanged.add(staff_selected_event_hook);

      xl_events.opportunity_active_cell(context);
    } catch (error) {
      console.error(error);
    }
    const sync_promise = context.sync();
    return sync_promise;
  });
}
