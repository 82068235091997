import * as xl_common from "../xl_common";
import $ from "jquery";

export async function find(search_text, context) {
    //find the row on the metadata tab
    async function get_from_ranges(range_array, context) {

        function get_map() {
            return {
                key: 0,
                cloud: 1,
                initials: 2,
                flag: 3,
                comment: 4
            };
        }
        var meta_rows = xl_common.get_row_objects(range_array, get_map(), context);
        //DEBUG
        //$("#message").html("1 - meta search meta_rows " + meta_rows[0].key);
        return meta_rows;
    }

    const search_in = context.workbook.worksheets.getItem("OPP-META-LOOKUP");
    var result = xl_common.find_row_objects(search_text, search_in, get_from_ranges);
    //DEBUG
    //$("#message").html("2 - meta find result " + result[0].row_object.key);
    return result;
}

export async function getMetaData(key, context) {
    //DEBUG
    //$("#message").html("meta search code = " + key);

    //build and return the response object 
    var find_results = await find(key, context);

    //for now, assume just one result
    if (find_results && find_results.length > 0) {
        //DEBUG
        //$("#message").html("4 - getMetaData result " + find_results[0].row_object.key);
        return find_results[0].row_object;
    } else {
        //DEBUG
        //$("#message").html("meta search not found" );
        return null;
    }
}

export async function set(key, context, flag, initials, comment) {
    var found = await find(key, context);
    if (found) {
        var cell = found[0].cell;
        var row = cell.rowIndex;
        var sheet = cell.worksheet;
        if (initials) {
            var cell_to_update = sheet.getCell(row, 2);
            cell_to_update.values = [[initials]];    
        }
        if (flag) {
            var cell_to_update = sheet.getCell(row, 3);
            cell_to_update.values = [[flag]];    
        }
        if (comment) {
            var cell_to_update = sheet.getCell(row, 4);
            cell_to_update.values = [[comment]];   
        }
        await context.sync();
    }
}