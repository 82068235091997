import * as xl_opportunities from "./opportunity/xl_opportunities";
import * as xl_opportunity_view from "./opportunity/xl_opportunity_view";
import * as xl_staffing_entries from "./staffing_entries/xl_staffing_entries";
import * as xl_staffing_entry_view from "./staffing_entries/xl_staffing_entry_view";
import $ from "jquery";

function make_event_hook(handler) {
  return function (event) {
    // eslint-disable-next-line no-undef
    return Excel.run(function (context) {
      return context.sync().then(function () {
        var event_handler_promise = handler(context, event);
        return event_handler_promise.then(() => {
          //nothing more to do
        });
      });
    });
  };
}

async function opportunity_active_cell(context) {
  var opportunity;
  try {
    $("#message").html("");
    opportunity = (await xl_opportunities.get_from_ranges([context.workbook.getActiveCell()], context))[0];
    //$("#message").html("opportunity json: <pre>" + JSON.stringify(opportunity) + "</pre>");
    xl_opportunity_view.display(opportunity);
  } catch (e) {
    // eslint-disable-next-line no-undef
    $("#message").html("error in opportunity_active_cell " + e);
    return;
  }
}

async function staffing_active_cell(context) {
  var staffing_entry;
  try {
    $("#message").html("");
    staffing_entry = (await xl_staffing_entries.get_from_ranges([context.workbook.getActiveCell()], context))[0];
    xl_staffing_entry_view.display(staffing_entry);
  } catch (e) {
    $("#message").html("error in staffing_active_cell " + e);
    return;
  }
}

export { make_event_hook, opportunity_active_cell, staffing_active_cell };
