import React from "react";
import * as xl_common from "../../staffing_modules/xl_common";

export default function AlignmentTable(props) {
  return (
    <table className="alignments">
      <thead>
        <tr>
          <th>Cap</th>
          <th>Name</th>
          <th>Title</th>
          {props.type == "primary" && <th>Start</th>}
          {props.type == "primary" && <th>Prob</th>}
        </tr>
      </thead>
      <tbody id="primary_alignments_tbody">
        {props.alignments.map((alignment) => (
          <tr>
            <td>{alignment.cap}</td>
            <td>{alignment.name}</td>
            <td>{alignment.title}</td>
            {props.type == "primary" && <td>{xl_common.excel_date_to_string(alignment.primary_start)}</td>}
            {props.type == "primary" && <td>{alignment.primary_percent}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
